<div *ngIf="!isFilePreview" class="medical-detal">

    <div class="pick-patient">
        <h3 style="color:#ff9200; text-align: center;">Medical Record Detail </h3>
    </div>
    <hr>
    <div class="row-profile">
        <div class="label-profile">
            Date
        </div>
        <div class="content-profile">
            {{moment(data.medical_record.date_created).format('DD/MM/YYYY HH:mm')}}
        </div>
    </div>
    <!-- <div class="row-profile">
        <div class="label-profile">
            Description
        </div>
        <div class="content-profile">
            {{data?.description}}
        </div>
    </div> -->
    <div class="row-profile">

        <div class="label-profile">
            Medication
        </div>
        <div class="content-profile">
            {{data?.medical_record.medication || data.medical_record.description}}
        </div>
    </div>

    <div class="header-info">
        Media
    </div>
    <div class="row-profile">
        <table class="table table-borderless">
            <thead style="color: #F3A565;">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Attachments</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let value of data.medical_record?.attachments;index as i">
                    <td>
                        {{i+1}}
                    </td>
                    <td>
                        <a (click)="previewFile(value.media_url)" href="javascript:;">{{value.description?value.description : "Show"}}</a>
                    </td>

                </tr>

            </tbody>
        </table>
    </div>
</div>

<div *ngIf="isFilePreview" class="medical-preview">
    <video *ngIf="typePreviewFile == 'video'" width="600" height="400" controls>
        <source src="{{previewUrl}}" type="video/mp4">
    </video>
    <img *ngIf="typePreviewFile == 'image'" width="600px" height="400px" src="{{previewUrl}}" />
    <div style="justify-content: flex-end;" class="row mt-2">
        <button (click)="closePreview()" class="btn btn-secondary float-right">Close</button>
    </div>

</div>
