<div class="row justify-content-center mt-0">
    <div class="col-11 col-sm-9 col-md-7 col-lg-6 text-center p-0 mt-3 mb-2">
        <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
            <h2><strong>Sign Up Your User Account</strong></h2>
            <p>Fill all form field to go to next step</p>
            <div class="row">
                <div class="col-md-12 mx-0">
                    <form id="msform">
                        <!-- progressbar -->
                        <ul id="progressbar">
                            <li class="active" id="account"><strong>Account</strong></li>
                            <li id="personal"><strong>Personal</strong></li>
                            <li id="payment"><strong>Payment</strong></li>
                            <li id="confirm"><strong>Finish</strong></li>
                        </ul>
                        <!-- fieldsets -->
                       
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>