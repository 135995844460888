import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AppHelper } from 'src/app/shared/helper/AppHelper';
@Component({
  selector: 'app-medical-record-detail',
  templateUrl: './medical-record-detail.component.html',
  styleUrls: ['./medical-record-detail.component.scss']
})
export class MedicalRecordDetailComponent implements OnInit {
  isFilePreview: boolean = false;
  typePreviewFile: string = 'none';
  previewUrl : string;
  moment = moment;
  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  previewFile(media_url) {
    let formatMedia = AppHelper.getMediaTypeUrl(media_url);
   
    if (formatMedia.type !== 'none') {
      this.typePreviewFile = formatMedia.type;
      this.isFilePreview = true;
      this.previewUrl = media_url;
    } else {
      window.open(media_url, '_blank');
      this.isFilePreview = false;
    }
  
  }

  closePreview(){
    this.typePreviewFile = 'none';
    this.isFilePreview = false;
    this.previewUrl = '';
  }

  ngOnInit(): void {
    console.log('isi data', this.data)
  }

}
