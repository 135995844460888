import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ShopComponent } from "./shop/shop.component";
import { PagesComponent } from "./pages/pages.component";
import { ElementsComponent } from "./elements/elements.component";
import { LandingComponent } from "./landing/landing.component"
import { AuthService } from "./shared/services/auth.service";
const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
    canLoad: [AuthService]
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
    canLoad: [AuthService]
  },
  {
    path: "shop",
    component: ShopComponent,
    loadChildren: () => import("./shop/shop.module").then((m) => m.ShopModule),
    canLoad: [AuthService]
  },
  {
    path: "pages",
    component: PagesComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
     canLoad: [AuthService]
  },
  {
    path: "elements",
    component: ElementsComponent,
    loadChildren: () =>
      import("./elements/elements.module").then((m) => m.ElementsModule),
      canLoad: [AuthService]

    },
  {
    path: "landing",
    component: LandingComponent,
    canLoad: [AuthService]

  },
  {
    path: "**", // Navigate to Home Page if not found any page
    redirectTo: "home/pets",
    canLoad: [AuthService]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
      useHash: false,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
