import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filtername",
})
export class SymptomNamePipe implements PipeTransform {
  transform(value: any[], input: string) {
    if (!value) {
      return [];
    }
    if (!input) {
      return value;
    }
    var valuesArr = [];
    if (input) {
      input = input.toLowerCase();
      console.log(input);
      console.log(value[0]);
      console.log();
      return value.filter((el: any) => el.toLowerCase().indexOf(input) > -1);
    }
    return value;
  }
}
