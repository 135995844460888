import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from "@angular/router";
import jwt_decode from "jwt-decode";
import { Observable } from "rxjs";
import { UserService } from "./user.service";

let token = localStorage.getItem("token") || "";
@Injectable({
  providedIn: "root",
})
export class AuthService implements CanLoad {
  token: any = "";

  constructor(private userService: UserService, private router: Router) {
    this.token = token;
  }

  private tokenExpired() {
    try {
      const expiry: any = jwt_decode(this.token);
      return Math.floor(new Date().getTime() / 1000) >= expiry.exp;
    } catch (error) {
      localStorage.removeItem('token')
      return true;
    }

  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.token !== "") {
      if (this.tokenExpired()) {
        this.userService.logout();
        return true;
      } else {
        return true
      }
    }
    return true
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if (this.token !== "") {
      if (this.tokenExpired()) {
        this.userService.logout();
        return true;
      } else {
        return true
      }
    } else {
      return this.router.navigate(['/'])
    }

  }
}
