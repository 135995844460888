<!--footer-->
<footer [ngClass]="class">
  <div class="white-layout">
    <div class="container">
      <section class="small-section">
        <div class="row footer-theme2">
          <div class="col">
            <div class="footer-link link-white">
              <div class="footer-brand-logo">
                <a href="javascript:void(0)">
                  <img [src]="themeLogo" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="social-white">
                <span class="text-black-50">Jl. Jati No.72, Pd. Jagung, Kec. Serpong Utara<br>
                  Kota Tangerang Selatan, Banten 15323<br>
                  <span class="fa fa-phone"></span> 0878-0969-7784 <span class="fa fa-instagram"></span> rajantitalkswithanimals </span>
              </div>
              <!--
              <div class="social-white">
                <ul>
                  <li>
                    <a href="javascript:void(0)"
                      ><i class="fa fa-facebook" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"
                      ><i class="fa fa-google-plus" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"
                      ><i class="fa fa-twitter" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"
                      ><i class="fa fa-instagram" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"
                      ><i class="fa fa-rss" aria-hidden="true"></i
                    ></a>
                  </li>
                </ul>
              </div>
              -->
              <div class="footer-contant">
                <ul>
                  <!--<li><a [routerLink]="['/pages/pet-checker', true]">Clinic Appointment</a></li>-->
                  <!-- <li><a href="javascript:void(0)">Dog Food</a></li>
                  <li><a href="javascript:void(0)">Cat Food</a></li>
                  <li><a href="javascript:void(0)">Accessories</a></li>
                  <li><a href="javascript:void(0)">Analyze Pet Sickness</a></li>
                  <li><a href="javascript:void(0)">Home Service</a></li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="sub-footer black-subfooter">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end">
            <p>
              <i class="fa fa-copyright" aria-hidden="true"></i>
              {{ today | date: "y" }} powered by <a target="_blank" style="color: #FFFFFF" href="https://komunestudio.com/"> Komune Studio</a>
            </p>
          </div>
        </div>
        <!-- <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/visa.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/mastercard.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/paypal.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/american-express.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/discover.png" alt="">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</footer>
<!-- footer end -->
