import { Component, OnInit, Input, HostListener } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header-four',
  templateUrl: './header-four.component.html',
  styleUrls: ['./header-four.component.scss']
})
export class HeaderFourComponent implements OnInit {

  @Input() class: string = 'header-2 header-6';
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() logoRTWA: string = 'assets/logo/rajanti_talks_with_animals_logo.jpeg'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  userDetail;
  constructor(private userService : UserService) { 
    this.userService.userIdChange.subscribe(change=>{
      console.log(change)
      if(change != null && change.length >0){
        this.userDetail = this.userService.userDetails;
      }
    })
  }

  ngOnInit(): void {
    this.userDetail = this.userService.userDetails;
    console.log(this.userDetail);
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 300 && window.innerWidth  > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  logout(){
    this.userService.logout();
  }

}
