import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
// import { ToasterService } from "./toaster.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { environment } from '../../../environments/environment'
let user_name = localStorage.getItem("user_name") || "";
let user_id = localStorage.getItem("user_id") || "";
let email = localStorage.getItem("email") || "";
let phone_numbers = localStorage.getItem("phone_numbers") || "";
let birthdate = localStorage.getItem("birthdate") || "";
let role = localStorage.getItem("role") || "";
let token = localStorage.getItem("token") || "";
@Injectable({
  providedIn: "root",
})
export class UserService {
  URL = environment.url_api_user;
  URL_NEO = environment.url_api_neo
  httpOptions = {
    headers: new HttpHeaders({
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Origin": "*",
      'Authorization': token ? `bearer ${token}` : null,
      "Content-Type": "application/json",
    }),
  };


  httpOptionsNoAuth = {
    headers: new HttpHeaders({
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    }),
  };

  user_name: any = "";
  userNameChange: Subject<any> = new Subject<any>();
  phone_numbers: any = "";
  phone_numbersChange: Subject<any> = new Subject<any>();
  birthdate: any = "";
  birthdateChange: Subject<any> = new Subject<any>();
  user_id: any = "";
  userIdChange: Subject<any> = new Subject<any>();
  email: any = "";
  emailChange: Subject<any> = new Subject<any>();
  role: any = "";
  roleChange: Subject<any> = new Subject<any>();
  token: any = "";
  tokenChange: Subject<any> = new Subject<any>();

  setDetails(user_name, email?, user_id?, phone_numbers?, birthdate?) {
    localStorage.setItem("user_name", user_name);
    localStorage.setItem("email", email);
    localStorage.setItem("phone_numbers", phone_numbers);
    localStorage.setItem("birthdate", birthdate);
    localStorage.setItem("user_id", user_id);
    this.userNameChange.next(user_name);
    this.emailChange.next(email);
    this.userIdChange.next(user_id);

    this.phone_numbersChange.next(phone_numbers);
    this.birthdateChange.next(birthdate);

    this.user_name = user_name;
    this.user_id = user_id;
    this.email = email;
    this.birthdate = birthdate;
    this.phone_numbers = phone_numbers;
  }

  get userDetails() {
    // console
    return {
      user_name: this.user_name,
      user_id: this.user_id.toString(),
      email: this.email,
      phone_numbers: this.phone_numbers,
      birthdate: this.birthdate,
    };
  }

  public logutNFC(pet_id) {
    localStorage.clear();
    this.router.navigate(["/pages/login"], { queryParams: { nfc: true, 'pet': pet_id } }).then(() => {
      window.location.reload();
    });;
  }
  public logout() {

    if(localStorage.getItem("token")){
      localStorage.clear();
      this.router.navigate(["/home"]).then(() => {
        window.location.reload();
      });;
    }
    else{
      localStorage.clear();
    }
    // localStorage.removeItem("user_name");
    // this.userNameChange.next("");
    // this.user_name = "";
    // localStorage.removeItem("email");
    // this.emailChange.next("");
    // this.email = "";
    // localStorage.removeItem("user_id");
    // this.userIdChange.next("");
    // this.user_id = "";
    // localStorage.removeItem("role");
    // this.roleChange.next("");
    // this.role = "";
    // localStorage.removeItem("token");
    // this.tokenChange.next("");
    // this.token = "";
    // // this.toastrService.showSuccessMessage("Succesfully Logged Out");

    // localStorage.removeItem("phone_numbers");
    // this.phone_numbersChange.next("");
    // this.phone_numbers = "";

    // localStorage.removeItem("birthdate");
    // this.birthdateChange.next("");
    // this.birthdate = "";

  }

  constructor(
    private http: HttpClient,
    // private toastrService: ToasterService,
    private router: Router
  ) {
    this.user_name = user_name;
    this.user_id = user_id;
    this.role = role;
    this.email = email;
    this.birthdate = birthdate;
    this.phone_numbers = phone_numbers;
    this.token = token;
  }

  // Add Coupon
  public login(credential) {
    return this.http.post(this.URL + "user/user-login", credential);
    // return this.http.post(this.URL + "user/user-loginshort", credential);
  }

  // Get Coupon
  public getCoupon() {
    return this.http.get(this.URL + "retrieve-coupons", this.httpOptions);
  }
  // Get Coupon
  public getUserSetting(user_id) {
    return this.http.get(
      this.URL + "retrieve-user-setting?user_name=" + user_id,
      this.httpOptions
    );
  }

  // get Dcotor
  public getDoctors() {
    return this.http.get(this.URL + "user/retrieve-doctor", this.httpOptions);
  }

  //Add Appointment User
  public getAppointment() {
    return this.http.get(
      this.URL + "user/retrieve-appointment",
      this.httpOptions
    );
  }
  //Add Appointment User
  public getAppointmentSingle() {
    return this.http.get(
      this.URL_NEO + "my-appointment-user",
      this.httpOptions
    );
  }
  //Add Appointment User
  public getSingleAppointment(appointment_id) {
    return this.http.get(
      this.URL + "user/add-appointment?user_name=" + appointment_id,
      this.httpOptions
    );
  }
  //Add Appointment User
  public addAppointment(appointment) {
    return this.http.post(
      this.URL + "user/add-appointment",
      appointment,
      this.httpOptions
    );
  }

  public registerPet(body) {
    return this.http.post<any>(this.URL_NEO + "patient", body, this.httpOptions)
  }
  public getSelf() {
    return this.http.get<any>(this.URL_NEO + "user/self", this.httpOptions)
  }
  public getByNameTag(petID) {
    if (token) {
      return this.http.get<any>(this.URL + "user/retrieve-patient-bytag?tag=" + petID, this.httpOptions)
    } else {
      return this.http.get<any>(this.URL + "user/retrieve-patient-bytag?tag=" + petID, this.httpOptionsNoAuth)
    }

  }


  public updatePet(body, id) {
    return this.http.put<any>(this.URL_NEO + "patient/" + id, body, this.httpOptions)
  }
  public deletePet(id) {
    return this.http.delete<any>(this.URL_NEO + "patient/" + id, this.httpOptions)
  }

  public requestForgotPassword(detail) {

    return this.http.post(
      this.URL_NEO + "user/forgot/password",
      detail,
      this.httpOptionsNoAuth
    );
  }

  public requestResetPassword(detail) {
    console.log(detail)
    return this.http.post(
      this.URL_NEO + "user/reset/password",
      detail,
      this.httpOptionsNoAuth
    );
  }
  public editProfile(detail) {

    return this.http.put(
      this.URL_NEO + "user/editself",
      detail,
      this.httpOptions
    );
  }

  public changePassword(detail) {
    return this.http.post(
      this.URL_NEO + "user/change/password",
      detail,
      this.httpOptions
    );
  }

  public registerUser(user) {
    return this.http.post(
      this.URL + "user/register-user",
      user
    );
  }
  public registerPatient(patient) {

    return this.http.post(this.URL + "user/add-patient", patient, this.httpOptions);
  }
  public getAnimalPatientPerUser(user_id) {
    return this.http.get(
      this.URL + "user/retrieve-patient?owner_id=" + user_id,
      this.httpOptions
    );
  }
  public getPetDetailPerUser(pet_id, is_owner) {

    return this.http.get(
      this.URL_NEO + "patient/" + pet_id,
      is_owner ? this.httpOptions : this.httpOptionsNoAuth
    );
  }
  public getPetVaccination(pet_id: Number | String) {

    return this.http.get(
      this.URL_NEO + "vaccination/patient/" + pet_id,
      this.httpOptions
    );
  }

  public updateUserSetting(object) {
    return this.http.post(
      this.URL + "update-user-setting",
      object,
      this.httpOptions
    );
  }

  public changeProfilePicture(file) {
    let Options = {
      headers: new HttpHeaders({
        // "Content-Type": "multipart/form-data",
      }),
    };
    return this.http.post(this.URL + "testImageUpload", file, Options);
  }

  public getUser() {
    return this.http.get(this.URL + "retrieve-users");
  }
  public getUserAsync() {
    return this.http.get(this.URL + "retrieve-users").toPromise();
  }
  public deleteUser(user) {
    return this.http.delete(this.URL + "delete-user?user_name=" + user);
  }
  public getUserAdmin() {
    return this.http.get(this.URL + "retrieve-users-admins");
  }

  public addUserAdmin(user) {
    return this.http.post(this.URL + "registerAdmin", user, this.httpOptions);
  }
  public updatePassword(user) {
    return this.http.post(
      this.URL + "update-admin-password",
      user,
      this.httpOptions
    );
  }

  public registerLomba(detail) {
    return this.http.post(
      this.URL + "user/register-participant",
      detail,
      this.httpOptions
    );
  }
}
