<div class="container">
    <div class="success-body">
        <div class="success-title">
            <i class="fas fa-check-circle text-success"></i>  Pembayaran Berhasil di kirim
        </div>
        <div class="success-content">
            Kami akan segera mengkonfirmasi pembayaran anda
        </div>
        <div class="success-notes">
            <small>Jika selama 1x24 jam belum terkonfirmasi harap hubungi customer support kami, terima kasih</small>
        </div>
  
    </div>

</div>